.main-content {
  margin-left: 0px;
  margin-top: calc(40px + 1.3rem);
  background-color: #f6f8fd;
  min-height: 100vh;
}

@media only screen and (min-width: 767.98px) {
  .main-content {
    margin-left: 250px;
  }
}

@media only screen and (max-width: 575.98px) {
  .card-img-top {
    height: 35vw !important;
  }
}

.badge-nav {
  display: block;
}

@media only screen and (max-width: 350px) {
  .badge-nav {
    display: none !important;
  }

  .ka-card-label {
    font-size: 3vw;
  }
}

.ka-primary {
  color: #f58634 !important;
}

.ka-secondary {
  color: #815aa4 !important;
}

.ka-active {
  background-color: #f58634 !important;
  color: white !important;
  font-weight: 500 !important;
}

.ka-primary-bg {
  background-color: #f58634 !important;
  border: none !important;
}

.ka-secondary-bg {
  background-color: #815aa4 !important;
  border: none !important;
}

.ka-primary-bg:hover,
.ka-secondary-bg:hover {
  filter: brightness(95%);
}

.icon-select {
  margin-top: 3px;
  /* don't take the whole line */
  display: flex;
  /* transition the background change */
  transition: background-color 0.25s;
  /* height of the icon */
  height: 2em;
  /* width of the icon */
  width: 2em;
  /* space between the icon and the background edge */
  padding: 0.25em;
  /* make it rounded */
  border-radius: 50%;
}

/* styles for the icon wrap when hovered */
.icon-select:hover {
  /* add a background on hover */
  background-color: rgba(0, 0, 0, 0.15);
  /* pointer cursor on hover */
  cursor: pointer;
}

.card-img-top {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

/* .swal2-popup {
  font-size: 0.8rem !important;
} */

.se-placeholder,
.txt,
.se-tooltip,
.se-dialog,
.se-btn-list {
  font-size: 15px !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.se-dialog-form-files input[type="file"] {
  padding-top: 3.5px !important;
}

.se-btn-primary {
  background-color: #f58634 !important;
  border: none !important;
  color: white !important;
}

.se-btn-primary:hover {
  filter: brightness(95%);
}

.thumbnail {
  width: 164px;
  height: 164px;
  overflow: hidden;
  margin: auto;
}
.thumbnail img {
  width: 164px;
  height: 100%;
  object-fit: cover;
}
